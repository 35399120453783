import { InstallExceptionRecordService } from '@/api';
import { DialogMixin } from '@/mixins/dialog';
import { CreateExceptionRecord, ExceptionRecordList, UpdateExceptionRecord } from '@/resource/model';
import { messageError, translation } from '@/utils';
import { Message } from 'element-ui';
import { ElForm } from 'element-ui/types/form';
import { mixins } from 'vue-class-component';
import { Component, InjectReactive, Prop } from 'vue-property-decorator';

@Component({
  components: {}
})
export default class AddExceptionRecord extends mixins(DialogMixin) {
  @InjectReactive()
  public readonly orderId!: number;

  @Prop({
    required: false,
    default: () => {
      return null;
    }
  })
  public exceptionRecord!: ExceptionRecordList | null;

  public exceptionForm: CreateExceptionRecord | UpdateExceptionRecord = {
    installOrderId: 0,
    description: '',
    processDescription: '',
    remark: ''
  };

  public formRules: { [P in keyof Partial<CreateExceptionRecord>]: Array<object> } = {
    description: [
      {
        required: true,
        validator: (rule: any, value: string, callback: Function): void => {
          if (!value) {
            callback(new Error(translation('exceptionRecord.inputExplain')));
            return;
          }
          callback();
        },
        trigger: 'blur'
      }
    ]
  };

  private operationType: 'add' | 'edit' = 'add';

  public created(): void {
    this.exceptionForm.installOrderId = this.orderId;
    if (!this.exceptionRecord) {
      this.operationType = 'add';
      this.title = 'exceptionRecord.add';
      return;
    }
    this.operationType = 'edit';
    this.title = 'exceptionRecord.edit';
    Object.assign(this.exceptionForm, this.exceptionRecord);
  }

  public async onSubmit(): Promise<void> {
    try {
      await (this.$refs.exceptionForm as ElForm).validate();
      this.setLoading(true);
      if (this.operationType === 'add') {
        await InstallExceptionRecordService.post(this.exceptionForm);
        Message.success(translation('operationRes.addSuccess'));
        this.$emit('add-success');
        this.closeDialog();
        return;
      }
      await InstallExceptionRecordService.put(this.exceptionForm as UpdateExceptionRecord);
      Message.success(translation('operationRes.editSuccess'));
      this.$emit('edit-success', this.exceptionForm);
      this.closeDialog();
    } catch (error) {
      if (error) {
        messageError(error);
      }
    } finally {
      this.setLoading(false);
    }
  }

  public destroyed(): void {
    this.$emit('dialog-closed');
  }
}
