import { customerService, FileService, installationOrderService, ServiceItemService } from '@/api';
import { OsTable } from '@/components';
import { OperationOption } from '@/components/os-table-operation/os-table-operation';
import { OsQueryItemOption } from '@/components/os-table-query/os-table-query';
import { OsTableOption, OsTableColumn, RowOperation } from '@/components/os-table/os-table';
import { PagingMixin } from '@/mixins/paging';
import { InstallationOrderStatusEnum, PageQueryPlanEnum } from '@/resource/enum';
import { InstallationOrderList, InstallationOrderListQuery } from '@/resource/model';
import {
  dateFormat,
  messageError,
  translation,
  showWarningConfirm,
  limitPreviousForTimePicker,
  messageErrors,
  dateIntervalFormat,
  permissionOperation,
  debounce,
  downloadFileByBlob
} from '@/utils';
import { Message } from 'element-ui';
import { mixins } from 'vue-class-component';
import { Component, ProvideReactive, Watch } from 'vue-property-decorator';
import ElImageViewer from 'element-ui/packages/image/src/image-viewer';
import { CustomColumnMixin } from '@/mixins/custom-column';
import { SortOptions } from '@/api/base';
import ExceptionRecord from './exception-record/exception-record.vue';
import BatchServiceTime from './batch-service-time/batch-service-time.vue';
import OsQueryPlan, { QueryPlanOperationOption } from '@/components/os-query-plan/os-query-plan';

@Component({
  name: 'InstallationOrder',
  components: {
    ElImageViewer,
    ExceptionRecord,
    BatchServiceTime
  }
})
export default class InstallationOrder extends mixins(PagingMixin, CustomColumnMixin) {
  /**
   * 页面标识
   */
  public code: number = PageQueryPlanEnum.installationOrder;
  /**
   * 查询方案编辑按钮
   */
  public queryPlanEndOption: Array<QueryPlanOperationOption> = [
    {
      id: 0,
      type: 'primary',
      slot: 'end',
      label: 'button.save',
      permissionCode: 'system:query:template:save',
      handleClick: (): void => {
        (this.$refs.OsQueryPlan as OsQueryPlan).dialogOpened();
      }
    },
    {
      id: 1,
      type: 'text',
      slot: 'end',
      label: 'button.edit',
      permissionCode: 'system:query:template:delete',
      handleClick: (): void => {
        (this.$refs.OsQueryPlan as OsQueryPlan).editDialogOpened();
      }
    }
  ];
  /**
   * 表格配置项
   */
  public tableOption: OsTableOption<InstallationOrderList> = {
    loading: false,
    data: [],
    fit: true,
    size: 'small',
    border: true,
    defaultSort: { order: 'ascending', prop: 'status' }
  };

  /**
   * 表格列配置
   */
  public tableColumnOptions: Array<OsTableColumn<InstallationOrderList>> = [
    {
      type: 'selection',
      prop: 'id',
      label: '',
      reserveSelection: true,
      fixed: true
    },
    {
      prop: 'code',
      label: 'installationOrder.code',
      minWidth: '200px',
      showOverflowTooltip: true,
      fixed: true,
      sortable: 'custom'
    },
    {
      prop: 'abstractContent',
      label: 'installationNotice.orderSummary',
      minWidth: '180px',
      showOverflowTooltip: true,
      fixed: true
    },
    {
      prop: 'customerName',
      label: 'customer.name',
      minWidth: '150px',
      showOverflowTooltip: true
    },
    {
      prop: 'shopName',
      label: 'customerShop.name',
      minWidth: '150px',
      showOverflowTooltip: true
    },
    {
      prop: 'address',
      label: 'customerShop.address',
      minWidth: '250px',
      showOverflowTooltip: true,
      formatter: (row: object): string => {
        if ((row as InstallationOrderList).province) {
          return `${(row as InstallationOrderList).province} 
          ${(row as InstallationOrderList).city ? (row as InstallationOrderList).city : ''} 
          ${(row as InstallationOrderList).district ? (row as InstallationOrderList).district : ''} 
          ${(row as InstallationOrderList).address}`;
        }
        return '--';
      }
    },
    {
      prop: 'contacts',
      label: 'customerShop.contact',
      minWidth: '120px',
      showOverflowTooltip: true
    },
    {
      prop: 'foremanName',
      label: 'installationOrder.installEmployee',
      minWidth: '120px',
      showOverflowTooltip: true
    },
    {
      prop: 'status',
      label: 'installationOrder.status',
      minWidth: '180px',
      showOverflowTooltip: true,
      sortable: 'custom'
    },
    {
      prop: 'serveItems',
      label: 'addInstallationNotice.serviceItem',
      minWidth: '180px',
      showOverflowTooltip: true
    },
    {
      prop: 'serveTime',
      label: 'installationOrder.serveTime',
      minWidth: '180px',
      showOverflowTooltip: true,
      formatter: (rowData: Object): string => {
        return dateIntervalFormat(
          (rowData as InstallationOrderList).serveStartTime,
          (rowData as InstallationOrderList).serveEndTime
        );
      }
    },
    {
      prop: 'reservationTime',
      label: 'addInstallationNotice.reservationTime',
      minWidth: '180px',
      showOverflowTooltip: true,
      formatter: (rowData: Object): string => {
        return `${dateFormat((rowData as InstallationOrderList).reservationTime)}`;
      }
    },
    // {
    //   prop: 'detailCount',
    //   label: 'installationOrder.detailCount',
    //   minWidth: '150px',
    //   showOverflowTooltip: true
    // },
    {
      prop: 'amount',
      label: 'customerShop.installCharge',
      minWidth: '150px',
      hide: permissionOperation('install:order:installCharge'),
      showOverflowTooltip: true
    },
    {
      prop: 'finishTime',
      label: 'addInstallationNotice.finishTime',
      minWidth: '180px',
      showOverflowTooltip: true,
      formatter: (rowData: Object): string => {
        return `${dateFormat((rowData as InstallationOrderList).finishTime, 'YYYY-MM-DD')}`;
      }
    },
    {
      prop: 'remark',
      label: 'common.remark',
      minWidth: '150px',
      showOverflowTooltip: true
    },
    {
      prop: 'createUserName',
      label: 'installationOrder.createUserName',
      minWidth: '150px',
      showOverflowTooltip: true
    },
    {
      prop: 'createTime',
      label: 'common.createTime',
      minWidth: '150px',
      showOverflowTooltip: true,
      formatter: (row: object): string => {
        return dateFormat((row as InstallationOrderList).createTime);
      },
      sortable: 'custom'
    }
  ];

  /**
   * 查询条件UI配置
   */
  public queryFormOption: Array<OsQueryItemOption<InstallationOrderListQuery>> = [
    {
      type: 'Input',
      field: 'code',
      label: 'installationOrder.code',
      option: {
        placeholder: 'deliveryNoticeList.inputCode'
      }
    },
    {
      type: 'Select',
      field: 'customerId',
      label: 'installationOrder.customer',
      option: {
        placeholder: 'installationOrder.customer',
        filterable: true
      },
      optionData: []
    },
    // {
    //   type: 'Input',
    //   field: 'projectName',
    //   label: 'project.name',
    //   option: {
    //     placeholder: 'project.inputName'
    //   }
    // },
    {
      type: 'Select',
      field: 'statusList',
      label: 'installationOrder.status',
      option: {
        placeholder: 'installationOrder.status',
        multiple: true
      },
      optionData: installationOrderService.getStatusSelectableList()
    },
    {
      type: 'Input',
      field: 'shopName',
      label: 'customerShop.name',
      option: {
        placeholder: 'customerShop.inputName'
      }
    },
    {
      type: 'DateRangePicker',
      field: 'reservationTime',
      label: 'addInstallationNotice.reservationTime',
      option: {
        placeholder: 'addInstallationNotice.reservationTime'
      }
    },
    {
      type: 'DateRangePicker',
      field: 'finishTime',
      label: 'installationOrder.finishTime',
      option: {
        placeholder: 'installationOrder.finishTime'
      }
    },
    {
      type: 'DateRangePicker',
      field: 'createTime',
      label: 'common.createTime',
      option: {
        pickerOptions: {
          disabledDate: limitPreviousForTimePicker
        }
      }
    },
    {
      type: 'Input',
      field: 'foremanName',
      label: 'installationOrder.installEmployee',
      option: {
        placeholder: 'common.inputKeyWord'
      }
    },
    {
      type: 'Select',
      field: 'serveItems',
      label: 'installationOrder.serveItem',
      option: {
        placeholder: 'common.select',
        filterable: true
      },
      optionData: []
    }
  ];

  /**
   * 查询表单对象
   */
  public queryForm: Partial<InstallationOrderListQuery> = {};
  public defaultQueryForm: Partial<InstallationOrderListQuery> = {
    code: '',
    customerId: undefined,
    statusList: [],
    shopName: '',
    reservationTime: [],
    finishTime: [],
    createTime: [],
    foremanName: '',
    serveItems: undefined
  };

  /**
   * 表格工具栏配置
   */
  public tableToolbarOptions: Array<OperationOption> = [
    {
      operationType: 'batchPush',
      slot: 'start',
      label: 'installationOrder.batchPush',
      type: 'primary',
      icon: 'el-icon-check',
      disabled: true,
      permissionCode: 'install:order:batchSubmit',
      handleClick: (): void => {
        this.batchPush();
      }
    },
    {
      operationType: 'batchCancel',
      slot: 'start',
      label: 'installationOrder.batchCancel',
      icon: 'el-icon-close',
      disabled: true,
      permissionCode: 'install:order:batchCancel',
      handleClick: (): void => {
        this.batchCancel();
      }
    },
    {
      operationType: 'batchFinsh',
      slot: 'start',
      label: 'installationOrder.batchFinish',
      type: 'primary',
      icon: 'el-icon-finished',
      disabled: true,
      permissionCode: 'install:order:batchFinish',
      handleClick: (): void => {
        this.batchFinish();
      }
    },
    {
      operationType: 'batchEditPrice',
      slot: 'start',
      label: 'installationOrder.batchEditPrice',
      type: 'primary',
      icon: 'el-icon-edit',
      disabled: true,
      permissionCode: 'install:order:editAmount',
      handleClick: (): void => {
        this.batchEditPrice();
      }
    },
    {
      operationType: 'batchDelete',
      slot: 'start',
      label: 'installationOrder.batchDelete',
      icon: 'el-icon-delete',
      disabled: true,
      permissionCode: 'install:order:batchDelete',
      handleClick: (): void => {
        this.batchDelete();
      }
    },
    {
      operationType: 'downloadZip',
      slot: 'start',
      label: 'installationOrder.downloadZip',
      icon: 'el-icon-folder-opened',
      disabled: true,
      permissionCode: 'install:order:downloadZip',
      handleClick: (): void => {
        this.downloadZip();
      }
    },
    {
      operationType: 'batchSetServiceTime',
      slot: 'start',
      label: 'installationOrder.batchSetServiceTime',
      icon: 'el-icon-edit',
      type: 'primary',
      disabled: true,
      permissionCode: 'install:order:editServeTime',
      handleClick: (): void => {
        this.batchSetServiceTime();
      }
    }
  ];

  /**
   * 表格行操作配置
   */
  public rowOperationOptions: RowOperation<InstallationOrderList> = {
    fixed: 'right',
    width: '180px',
    operations: [
      {
        operationType: 'edit',
        type: 'text',
        label: 'button.edit',
        icon: 'el-icon-edit',
        size: 'small',
        permissionCode: 'install:order:edit',
        handleClick: this.linkToEdit,
        dynamicHidden: (rowData: InstallationOrderList): boolean => {
          return Number(rowData.status) !== InstallationOrderStatusEnum.toBePushed;
        }
      }
      // {
      //   operationType: 'exception',
      //   type: 'text',
      //   label: 'installationOrder.exceptionRecord',
      //   icon: 'el-icon-warning',
      //   size: 'small',
      //   permissionCode: 'install:order:exception:listPage',
      //   handleClick: this.openExceptionDialog
      // }
    ]
  };

  public dialogVisible = false;
  public setServiceTimeDialogVisible = false;

  public orderIds: Array<number> = [];

  /**
   * 控制异常记录dialog
   */
  public exceptionRecordVisible = false;

  @ProvideReactive()
  public orderId = 0;

  private selectedRows: Array<InstallationOrderList> = [];

  private sortOptions: SortOptions<InstallationOrderList> = this.tableOption.defaultSort!;

  public activated(): void {
    this.reloadData();
  }

  public created(): void {
    this.initCustomSelectableList();
    this.initServeItemSelectableList();
    this.initColumns(this.tableColumnOptions, 'installationOrder');
    this.loadData();
  }
  /**
   * 执行查询方案
   * @param json
   */
  public queryList(json: string): void {
    this.paging.currentPage = 1;
    Object.assign(this.queryForm, this.defaultQueryForm);
    Object.assign(this.queryForm, JSON.parse(json));
    this.loadData();
  }

  /**
   * 去订单详情
   * @param rowData 行数据
   */
  public linkToDetails(rowData: InstallationOrderList): void {
    this.$router.push({
      path: '/installation-order-detail',
      query: {
        id: rowData.id.toString()
      }
    });
  }

  /**
   * 检索按钮
   */
  public handleQueryClick(): void {
    this.paging.currentPage = 1;
    this.reloadData();
  }

  /**
   * 重新加载数据
   */
  public reloadData(): void {
    (this.$refs.tableRef as OsTable).clearSelection();
    this.selectedRows = [];
    this.loadData();
  }

  public handleSortChange(sortOptions: SortOptions<InstallationOrderList>): void {
    this.paging.currentPage = 1;
    this.sortOptions = sortOptions;
    this.reloadData();
  }

  /**
   * 根据状态获取i18n key
   * @param status 状态值
   * @returns
   */
  public getStatusI18Key(status: InstallationOrderStatusEnum): string {
    return installationOrderService.getStatusI18Key(status);
  }

  /**
   * 根据状态获取class
   * @param status  状态值
   * @returns
   */
  public getStatusClass(status: InstallationOrderStatusEnum): string {
    return installationOrderService.getStatusClass(status);
  }

  /**
   * 表格行选中事件
   */
  public handleSelectionChange(selectedData: Array<InstallationOrderList>): void {
    this.selectedRows = selectedData;
  }

  /**
   * 表格行选中数据监听
   */
  @Watch('selectedRows')
  public handleSelectionChanged(value: Array<InstallationOrderList>): void {
    this.tableToolbarOptions.forEach(option => {
      option.disabled = value.length === 0;
    });
  }

  @Watch('queryForm.createTime')
  private handleCreateTimeChanged(value: Array<string>): void {
    if (!value || value.length === 0) {
      this.queryForm.createStartTime = undefined;
      this.queryForm.createEndTime = undefined;
      return;
    }
    if (value && value.length === 2) {
      this.queryForm.createStartTime = dateFormat(value[0]);
      this.queryForm.createEndTime = dateFormat(value[1], 'YYYY-MM-DD') + ' 23:59:59';
    }
  }

  @Watch('queryForm.reservationTime')
  private handleInstallTimeChanged(value: Array<string>): void {
    if (!value || value.length === 0) {
      this.queryForm.reservationStartTime = undefined;
      this.queryForm.reservationEndTime = undefined;
      return;
    }
    if (value && value.length === 2) {
      this.queryForm.reservationStartTime = dateFormat(value[0]);
      this.queryForm.reservationEndTime = dateFormat(value[1], 'YYYY-MM-DD') + ' 23:59:59';
    }
  }

  @Watch('queryForm.finishTime')
  private handlePlanInstallTimeChanged(value: Array<string>): void {
    if (!value || value.length === 0) {
      this.queryForm.finishStartTime = undefined;
      this.queryForm.finishEndTime = undefined;
      return;
    }
    if (value && value.length === 2) {
      this.queryForm.finishStartTime = dateFormat(value[0]);
      this.queryForm.finishEndTime = dateFormat(value[1], 'YYYY-MM-DD') + ' 23:59:59';
    }
  }

  /**
   * 数据加载
   */
  @debounce()
  private loadData(): void {
    this.tableOption.loading = true;
    installationOrderService
      .getList(this.queryForm, this.paging, this.sortOptions)
      .then(res => {
        this.tableOption.data = res.data;
        this.totalData = res.total;
      })
      .catch(error => {
        messageError(error);
      })
      .finally(() => {
        this.tableOption.loading = false;
      });
  }

  /**
   * 初始化当前用户可选客户列表
   */
  private async initCustomSelectableList(): Promise<void> {
    const list = await customerService.getAllUsingCustomer();
    const selectableList: Array<any> = [];
    list.forEach(item => {
      selectableList.push({
        label: item.companyName,
        value: item.id
      });
    });

    const option = this.queryFormOption.find(item => item.field === 'customerId');
    option!.optionData = selectableList;
  }
  /**
   * 初始化服务项目列表
   */
  private async initServeItemSelectableList(): Promise<void> {
    const list = await ServiceItemService.listForComponents();
    const selectableList: Array<any> = [];
    list.data.forEach(item => {
      selectableList.push({
        label: item.name,
        value: item.name
      });
    });

    const option = this.queryFormOption.find(item => item.field === 'serveItems');
    option!.optionData = selectableList;
  }

  /**
   * 批量删除
   */
  private batchDelete(): void {
    const availableRows = this.selectedRows.filter(
      row => Number(row.status) === InstallationOrderStatusEnum.toBePushed
    );

    if (availableRows.length <= 0) {
      Message.warning(translation('installationOrder.selectNewReceiptsTip'));
      return;
    }

    showWarningConfirm(translation('tip.confirmDelete'))
      .then(async () => {
        try {
          const idList: Array<number> = availableRows.map(row => row.id);
          await installationOrderService.batchDelete(idList);
          this.reloadData();
          Message.success(translation('operationRes.deleteSuccess'));
        } catch (error) {
          messageErrors(error);
        }
      })
      .catch(() => {
        Message.info(translation('operationRes.cancelDelete'));
      });
  }
  /**
   * Zip文件下载
   */
  private downloadZip(): void {
    const availableRows = this.selectedRows;

    if (availableRows.length <= 0) {
      Message.warning(translation('installationOrder.selectNewReceiptsTip'));
      return;
    }
    try {
      const idList: Array<number> = availableRows.map(row => row.id);
      installationOrderService
        .downloadZip(idList)
        .then(result => {
          if (result.length <= 0) {
            Message.warning('没有可下载文件');
            return;
          }
          FileService.downloadGroupZip(result)
            .then(blob => {
              downloadFileByBlob(`完工照_${dateFormat(new Date())}.zip`, blob);
            })
            .catch(error => {
              messageError(error);
            })
            .finally(() => {});
        })
        .catch(err => {
          console.log(err);
        })
        .finally(() => {});
    } catch (error) {
      messageErrors(error);
    }
  }

  /**
   * 批量取消
   */
  private batchCancel(): void {
    const availableRows = this.selectedRows.filter(row =>
      [
        InstallationOrderStatusEnum.PendingOrders,
        InstallationOrderStatusEnum.pendingAppointment,
        InstallationOrderStatusEnum.inService
      ].includes(Number(row.status))
    );

    if (availableRows.length <= 0) {
      Message.warning(translation('installationOrder.selectWaitingInstallTip'));
      return;
    }

    showWarningConfirm(translation('installationOrder.confirmCancel'))
      .then(async () => {
        try {
          const idList: Array<number> = availableRows.map(row => row.id);
          await installationOrderService.batchCancel(idList);
          this.reloadData();
          Message.success(translation('operationRes.operationSuccess'));
        } catch (error) {
          messageErrors(error);
        }
      })
      .catch(() => {
        Message.info(translation('operationRes.operationCanceled'));
      });
  }

  /**
   * 批量修改价格
   */
  private batchEditPrice(): void {
    this.$router.push({
      path: 'batch-edit-price',
      query: {
        orderList: JSON.stringify(this.selectedRows)
      }
    });
  }

  private batchPush(): void {
    const availableRows = this.selectedRows.filter(
      row => Number(row.status) === InstallationOrderStatusEnum.toBePushed
    );

    if (availableRows.length <= 0) {
      // TODO 修改翻译
      Message.warning(translation('installationOrder.selectNewOrderTip'));
      return;
    }

    showWarningConfirm(translation('installationOrder.confirmPushOrder'))
      .then(async () => {
        try {
          const idList: Array<number> = availableRows.map(row => row.id);
          await installationOrderService.batchPush(idList);
          this.reloadData();
          Message.success(translation('operationRes.operationSuccess'));
        } catch (error) {
          messageErrors(error);
        }
      })
      .catch(() => {
        Message.info(translation('operationRes.operationCanceled'));
      });
  }

  /**
   * 批量完成
   */
  private batchFinish(): void {
    const availableRows = this.selectedRows.filter(row => row.status === InstallationOrderStatusEnum.toBePushed);
    if (availableRows.length <= 0) {
      Message.warning(translation('installationOrder.selectNewReceiptsTip'));
      return;
    }

    showWarningConfirm(translation('installationOrder.confirmFinishInstall'))
      .then(async () => {
        try {
          const idList: Array<number> = availableRows.map(row => row.id);
          await installationOrderService.batchFinish(idList);
          this.reloadData();
          Message.success(translation('operationRes.operationSuccess'));
        } catch (error) {
          messageErrors(error);
        }
      })
      .catch(() => {
        Message.info(translation('operationRes.operationCanceled'));
      });
  }

  /**
   * 编辑资源
   * @param rowData 资源对象
   */
  private linkToEdit(rowData: InstallationOrderList): void {
    this.$router.push({
      path: 'edit-installation-order',
      query: {
        id: rowData.id.toString()
      }
    });
  }

  private openExceptionDialog(row: InstallationOrderList): void {
    this.orderId = row.id;
    this.exceptionRecordVisible = true;
  }
  private batchSetServiceTime(): void {
    this.orderIds = this.selectedRows.map(item => item.id!);
    this.setServiceTimeDialogVisible = true;
  }

  private editSuccess(): void {
    this.loadData();
  }
}
