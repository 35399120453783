import { InstallExceptionRecordService } from '@/api';
import { OperationOption } from '@/components/os-table-operation/os-table-operation';
import { OsTableColumn, OsTableOption, RowOperation } from '@/components/os-table/os-table';
import { DialogMixin } from '@/mixins/dialog';
import { PagingMixin } from '@/mixins/paging';
import { ExceptionRecordList } from '@/resource/model';
import { dateFormat, messageError, showWarningConfirm, translation } from '@/utils';
import { Message } from 'element-ui';
import { ElTable } from 'element-ui/types/table';
import { mixins } from 'vue-class-component';
import { Component, InjectReactive, Watch } from 'vue-property-decorator';
import AddExceptionRecord from './add-exception-record/add-exception-record.vue';
@Component({
  components: {
    AddExceptionRecord
  }
})
export default class ExceptionRecord extends mixins(DialogMixin, PagingMixin) {
  @InjectReactive()
  public readonly orderId!: number;

  public tableOption: OsTableOption<ExceptionRecordList> = {
    loading: false,
    data: [],
    fit: true
  };

  /**
   * 表格列配置
   */
  public columnOption: Array<OsTableColumn<ExceptionRecordList>> = [
    {
      type: 'selection',
      prop: 'id',
      label: '',
      reserveSelection: true
    },
    {
      prop: 'description',
      label: 'exceptionRecord.explain',
      showOverflowTooltip: true,
      minWidth: '150px'
    },
    {
      prop: 'processDescription',
      label: 'exceptionRecord.handle',
      showOverflowTooltip: true,
      minWidth: '150px'
    },
    {
      prop: 'remark',
      label: 'common.remark',
      showOverflowTooltip: true,
      minWidth: '150px'
    },
    {
      prop: 'createUserName',
      label: 'common.createUser',
      showOverflowTooltip: true,
      minWidth: '120px'
    },
    {
      prop: 'createTime',
      label: 'common.createTime',
      showOverflowTooltip: true,
      minWidth: '180px',
      formatter: (row: object): string => {
        return dateFormat((row as ExceptionRecordList).createTime);
      }
    }
  ];

  /**
   * table上方的表格操作配置
   */
  public operationOptions: Array<OperationOption> = [
    {
      type: 'primary',
      slot: 'start',
      label: 'button.add',
      operationType: 'add',
      icon: 'el-icon-circle-plus-outline',
      permissionCode: 'install:order:exception:save',
      handleClick: this.openAddDialog
    },
    {
      operationType: 'batchDelete',
      slot: 'start',
      label: 'installationOrder.batchDelete',
      icon: 'el-icon-delete',
      disabled: true,
      permissionCode: 'install:order:exception:batchDelete',
      handleClick: this.batchDelete
    }
  ];

  /**
   * table行的操作配置
   */
  public rowOperationOptions: RowOperation<ExceptionRecordList> = {
    fixed: 'right',
    width: '100px',
    operations: [
      {
        operationType: 'edit',
        type: 'text',
        label: 'button.edit',
        icon: 'el-icon-edit',
        permissionCode: 'install:order:exception:edit',
        handleClick: this.openEditDialog
      }
    ]
  };

  public selectedRows: Array<ExceptionRecordList> = [];

  public pendingEditRow: ExceptionRecordList | null = null;

  public addVisible = false;

  public created(): void {
    this.loadData();
  }

  public openAddDialog(): void {
    this.addVisible = true;
  }

  public openEditDialog(row: ExceptionRecordList): void {
    this.pendingEditRow = row;
    this.addVisible = true;
  }

  public handleSelectionChange(selectedRows: Array<ExceptionRecordList>): void {
    this.selectedRows = selectedRows;
  }

  public loadData(): void {}

  public handleEditSuccess(data: ExceptionRecordList): void {
    const findItem = this.tableOption.data.find(x => x.id === data.id);
    Object.assign(findItem, data);
  }

  public resetEditRow(): void {
    this.pendingEditRow = null;
  }

  private async batchDelete(): Promise<void> {
    try {
      await showWarningConfirm(translation('tip.confirmDelete'));
      this.tableOption.loading = true;
      await InstallExceptionRecordService.batchDelete(this.selectedRows.map(x => x.id));
      this.reloadData();
      Message.success(translation('operationRes.deleteSuccess'));
    } catch (error) {
      if (error === 'cancel') {
        Message.info(translation('operationRes.cancelDelete'));
        return;
      }
      messageError(error);
    } finally {
      this.tableOption.loading = false;
    }
  }

  private reloadData(): void {
    (this.$refs.exceptionTable as ElTable).clearSelection();
    this.selectedRows = [];
    this.loadData();
  }

  @Watch('selectedRows')
  private handleSelectedChanged(value: Array<ExceptionRecordList>): void {
    const dynamicBtnType = ['batchDelete'];
    this.operationOptions.forEach(x => {
      if (dynamicBtnType.includes(x.operationType)) {
        x.disabled = value.length === 0;
      }
    });
  }
}
