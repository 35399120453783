import { installationOrderService } from '@/api';

import { DialogMixin } from '@/mixins/dialog';
import { limitFutureForTimePicker, messageError, translation } from '@/utils';
import { Form, Message } from 'element-ui';
import { mixins } from 'vue-class-component';
import { Component, Prop } from 'vue-property-decorator';

@Component({
  name: 'BatchServiceTime',
  components: {}
})
export default class BatchServiceTime extends mixins(DialogMixin) {
  @Prop({
    required: true,
    type: Object,
    default: () => {
      return [];
    }
  })
  public orderIds!: Array<number>;
  public form: { serveTime: Array<string> } = {
    serveTime: []
  };

  public resourceFormRules = {
    serveTime: [
      {
        required: true,
        validator: (rule: any, value: Array<string>, callback: Function): void => {
          if (!value) {
            callback(new Error(translation('installationOrder.selectServeTime')));
            return;
          }
          callback();
        },
        trigger: 'change'
      }
    ]
  };

  public limitDateRangeOption = {
    disabledDate: limitFutureForTimePicker
  };

  public dialogOpen(): void {
    this.form.serveTime = [];
  }

  public dialogClosed(): void {
    this.$emit('dialog-closed');
    (this.$refs.serviceTimeForm as Form).resetFields();
  }
  public onSubmit(): void {
    (this.$refs.serviceTimeForm as Form).validate(async (valid: boolean) => {
      if (!valid) {
        return;
      }
      this.setLoading(true);
      try {
        // const serverStartTime = dateFormat(this.form.serveTime[0], 'YYYY-MM-DD HH:mm');
        // const serverEndTime = dateFormat(this.form.serveTime[1], 'YYYY-MM-DD HH:mm');
        const serverStartTime = this.form.serveTime[0];
        const serverEndTime = this.form.serveTime[1];
        await installationOrderService.batchSetServeTime(this.orderIds, serverStartTime, serverEndTime);
        Message.success(translation('operationRes.operationSuccess'));
        this.$emit('editSuccess');
        this.closeDialog();
      } catch (error) {
        messageError(error);
      } finally {
        this.setLoading(false);
      }
    });
  }
}
